import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../api/api.js"
import moment from "moment-timezone";
import { AuthContext } from "../../auth/AuthProvider.js";
// import Swal from "sweetalert2";
import { Row, Col, Form, Button } from "react-bootstrap";
import "../../styles/app.css";
import { getMasterItemID, getMasterVendor } from "../utils/utilsGetData.js";
import { showToastDelItem, showToastExist, showToastFail } from "../utils/utilsModalDialog.js";


export function TransactionIn(){
    const userLogin     = useContext(AuthContext);
    const location      = useLocation();
    const queryParams   = new URLSearchParams(location.search);
    
    // state definition
    const [listVendor, setListVendor]           = useState([]);
    const [listItem, setListItem]               = useState([]);
    const [listCustomsDoc, setListCustomsDoc]   = useState([]);
    const [listTransaction, setListTransaction] = useState([]);
    const [listItemAdded, setListItemAdded]     = useState([]);
    const [inputDocStatus, setInputDocStatus]   = useState('');
    const [inputDocDetail, setInputDocDetail]   = useState([]);

    // var definition
    const searchTerm    = queryParams.get('doctype');
    const urlPostH      = `/api/inbound/${searchTerm}/header/new`;
    const urlPostD      = `/api/inbound/${searchTerm}/detail/new`;
    var DocumentTitle;
    var DocumentCategory;
    
    //console.log(inputDocDetail);

    // check document title & category based on searchTerm
    switch(searchTerm){
        case 'mat':
            DocumentTitle       = 'PENERIMAAN MATERIAL';
            DocumentCategory    = 'MATERIAL';        
        break;
        case 'eq':
            DocumentTitle       = 'PENERIMAAN EQUIPMENT & SPAREPART';
            DocumentCategory    = 'EQUIPMENT';
        break;
        case 'was':
            DocumentTitle       = 'PENERIMAAN EXCESS & WASTE';
            DocumentCategory    = 'WASTE';
        break;
        case 'fg':
            DocumentTitle       = 'PENERIMAAN FINISH GOOD';
            DocumentCategory    = 'FINISH GOOD';
        break;
        case 'prod':
            DocumentTitle       = 'PENERIMAAN PRODUKSI';
            DocumentCategory    = 'PRODUCTION IN';
        break;
        default:
            DocumentTitle       = 'PENERIMAAN MATERIAL';
            DocumentCategory    = '';
        break;
    }

    // fetch item based on document id
    const fetchItemAdded = async (DocumentID) => {
        try {
            if(DocumentID !== null) {
                const response = await axios.get(`/api/inbound/detail/${encodeURIComponent(DocumentID)}`);
                setListItemAdded(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // useeffect to fetch data master
    useEffect(() => {
        fetchItemAdded(inputDocDetail.DocumentID);
    }, [inputDocDetail.DocumentID]);
    
    useEffect(() => {
        const fetchDataMaster = async (DocumentCategory, searchTerm) => {
            try {
                const masterCustomDoc   = await axios.get("/api/data/customsdoc");
                const listTrx           = await axios.get(`/api/data/transaction/${DocumentCategory}/in`);
                const listVendor        = await getMasterVendor(); 
                const listItem          = await getMasterItemID(searchTerm);
                setListVendor(listVendor);
                setListTransaction(listTrx.data.data);
                setListItem(listItem);
                setListCustomsDoc(masterCustomDoc.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataMaster(DocumentCategory, searchTerm);
    }, []);
    
    const ocDocHeader = (event) => {
        const { name, value } = event.target;
        // if(name==='ItemID'){
        //     const checkItem = listItem.filter(item => item.ItemID.includes(value));
        //     if(checkItem.length===0 && value.length >= 5) return event.target.value;
        // }
        setInputDocDetail(prevState => ({
            ...prevState,
            [name]: value,
            CreateBy: userLogin.value.userName,
            CreateDate: moment().format("YYYY-MM-DD hh:mm:ss")
        }));
    };


    // save document header
    const saveDocHeader = (event) => {
        try {
            event.preventDefault();
            axios
                .post(urlPostH, { headerData: inputDocDetail })
                .then((response) => { if (response.status === 200) { setInputDocStatus("SAVED") } })
                .catch((error) => { if (error.response) return showToastExist });
          } catch (error) {
            return console.log(error);
          }
    }

    // save document data function
    const saveDocData = (event) => {
        try {
            event.preventDefault();
            const filterObject = obj => {
                const filteredObj = {};
                for (const [key, value] of Object.entries(obj)) {
                  if (key !== "ItemID" || value !== null) {
                    filteredObj[key] = value;
                  }
                }
                return filteredObj;
            };
            axios
            .post(urlPostD, { detailData: inputDocDetail })
            .then((response) => { if (response.status === 200) { fetchItemAdded(inputDocDetail.DocumentID) }})
            .catch((error) => { if (error.response) return showToastFail() });
          } catch (error) {
            return console.log(error);
          }
    }


    const ocBtnDelItem = async(event, DocumentID, ItemID, idLogTrx) => {
        event.preventDefault();
        const deleteItem    = await axios.delete(`/api/inbound/${encodeURIComponent(DocumentID)}/${encodeURIComponent(ItemID)}/${idLogTrx}`);
        if(!deleteItem) return console.log(`fail to delete`);
        fetchItemAdded(DocumentID);
        return showToastDelItem();
    }
    
    return(
        <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            <Col sm={12} lg={12}>
                                <br/>
                                <h5 className="m-0">TRANSAKSI DATA - {DocumentTitle} </h5>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputTransactionType" className="form-label font-xsmall">Transaksi</label>
                                            <select className="form-control form-control-sm font-xsmall" name="DocumentType" id="inputTrxType" value={inputDocDetail.DocumentType} onChange={ocDocHeader} required>
                                                <option value={""} disabled selected>  </option>
                                                {listTransaction && listTransaction.map((item, index) => (
                                                    <option key={index} value={item.trx_name} >{item.trx_name} </option> 
                                                ))}
                                            </select>
                                        </Col>
                                        
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocType" className="form-label font-xsmall">Tipe Dok BC</label>
                                            <select className="form-control form-control-sm font-xsmall" name="CustomsDocType" value={inputDocDetail.CustomsDocType} id="inputCustomsDocType" onChange={ocDocHeader} required>
                                                    <option value={""} disabled selected>  </option>
                                                {listCustomsDoc && listCustomsDoc.map((item, index) => (
                                                    <option key={index} value={item.CustomsDocType} >{item.CustomsDocType} </option> 
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocID" className="form-label font-xsmall">Nomor Dok BC</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="CustomsDocID" id="inputCustomsDocID" onChange={ocDocHeader}></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocDate" className="form-label font-xsmall">Tanggal Dok BC</label>
                                            <input type="date" className="form-control form-control-sm font-xsmall" name="CustomsDocDate" id="inputCustomsDocDate" onChange={ocDocHeader}></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentID" className="form-label font-xsmall">Nomor Dokumen LPB</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="DocumentID" id="inputDocumentID" onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentDate" className="form-label font-xsmall">Tanggal Dokumen</label>
                                            <input type="date" className="form-control form-control-sm font-xsmall" name="DocumentDate" id="inputDocumentDate" onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputVendorID" className="form-label font-xsmall">Pengirim</label>
                                            <select className="form-control form-control-sm font-xsmall" name="VendorID" id="inputVendorID" onChange={ocDocHeader} required>
                                                {listVendor.map((item, index) => (
                                                    <option key={item.VendorID} value={item.VendorID} >{item.VendorID} - {item.VendorName} </option> 
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputPackingSlipID" className="form-label font-xsmall">Surat Jalan</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="PackingSlipID" id="inputPackingSlipID" onChange={ocDocHeader} ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputTransportID" className="form-label font-xsmall">Transport ID / Trucking</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="TransportID" id="inputTransportID" onChange={ocDocHeader} ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputInspectionDate" className="form-label font-xsmall">Tanggal IQC</label>
                                            <input type="date" className="form-control form-control-sm font-xsmall" name="InspectionDate" id="inputInspectionDate" onChange={ocDocHeader}></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputBLDocID" className="form-label font-xsmall">Nomor BL</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="BLDocID" id="inputBLDocID" onChange={ocDocHeader} ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputRemark" className="form-label font-xsmall">Catatan / Remark</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="Remark" id="inputRemark" onChange={ocDocHeader} ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={3} xl={2} className="px-1 py-0 form-group">
                                            <br/>
                                            <Button className="btn btn-success font-xsmall" onClick={saveDocHeader}><i className="right fas fa-save"></i></Button>&nbsp;
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <hr/>
                                <p><strong>Tambah Detail Material</strong></p>                            
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputMPOID" className="form-label font-xsmall">MPO</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="MPOID" id="inputMPOID" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemID" className="form-label font-xsmall">Item ID</label>
                                            <input type="text" list="listItem" className="form-control form-control-sm font-xsmall" name="ItemID" id="inputItemID" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'} ></input>
                                            <datalist id="listItem">
                                                {listItem.map((item, index) => (
                                                    <option key={item.ItemID} value={item.ItemID} >{item.ItemID} - {item.ItemDescription}</option> 
                                                ))}
                                            </datalist>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemColor" className="form-label font-xsmall">Item Color</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputItemColor" name="ItemColor" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputSerial" className="form-label font-xsmall">Item Serial</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputSerial" name="ItemSerial" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputVendorID" className="form-label font-xsmall">HS Code</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputVendorID" name="ItemHSCode" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputUOM" className="form-label font-xsmall">UOM</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputUOM" name="UOM" maxLength={"3"} onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'} style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputQty" className="form-label font-xsmall">Jumlah</label>
                                            <input type="number" step={'0.00'} className="form-control form-control-sm font-xsmall" name="Qty" id="inputQty" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputCurrency" className="form-label font-xsmall">Mata Uang</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputCurrency" maxLength={"3"} name="Currency" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'} style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputUnitPrice" className="form-label font-xsmall">Harga Satuan</label>
                                            <input type="number" step={"0.0000"} className="form-control form-control-sm font-xsmall" id="inputUnitPrice" name="UnitPrice" onChange={ocDocHeader} required disabled={inputDocStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group">
                                            <br/>
                                            <button type="button" className="btn btn-success" onClick={saveDocData} disabled={inputDocStatus !== 'SAVED'}><i className="right fas fa-plus"></i></button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr className="bg-primary shadow" style={{fontSize: "x-small"}}>
                                            <th>MPO</th>
                                            <th>Item ID</th>
                                            <th>Deskripsi</th>
                                            <th>Serial</th>                                           
                                            <th>HS Code</th>
                                            <th>Satuan</th>
                                            <th>Jumlah</th>
                                            <th>Mata Uang</th>
                                            <th>Harga Unit</th>
                                            <th>Nilai Barang</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listItemAdded.map((item, index) => (
                                        <tr key={index} style={{fontSize: "x-small"}}>
                                            <td>{item.MPOID}</td>
                                            <td>{item.ItemID}</td>
                                            <td>{item.ItemDescription}</td>
                                            <td>{item.ItemSerial}</td>
                                            <td>{item.ItemHSCode}</td>
                                            <td>{item.UOM}</td>
                                            <td>{item.Qty}</td>
                                            <td>{item.Currency}</td>
                                            <td>{item.UnitPrice}</td>
                                            <td>{item.Qty * item.UnitPrice}</td>
                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={(event) => ocBtnDelItem(event, item.DocumentID, item.ItemID, item.idLogTrx)}><i className="right fas fa-trash"></i></button></td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>
    )
}